import React from "react";
import { useTranslation } from "react-i18next";

const Logo = () => {
  const { t } = useTranslation();

  return (
    <div className="logo">
      <span>
        {t("myFirstName")}
        <br /> {t("myLastName")}
      </span>
      <span className="decoration"></span>
    </div>
  );
};

export default Logo;
