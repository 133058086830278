import React from "react";

import Link from "@components/Link";
import Logo from "@components/Logo";
import Navigation from "@components/Navigation";
import MainQuote from "@components/MainQuote";
import LanguageSelect from "@components/LanguageSelect";

const Menu = () => {
  return (
    <div className="menu">
      <div className="wrapper-center">
        <Link className="home-link" href="/">
          <Logo />
        </Link>
        <Navigation />
      </div>
      <div className="wrapper-left">
        <LanguageSelect />
        <MainQuote />
      </div>
    </div>
  );
};

export default Menu;
