import React from "react";
import PropTypes from "prop-types";

const LanguageOption = ({ icon, alt = "", label = "" }) => {
  return (
    <div className="language-option">
      <img src={icon} alt={alt} />
      {label && <span className="label">{label}</span>}
    </div>
  );
};

LanguageOption.propTypes = {
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string,
  label: PropTypes.string,
};

export default LanguageOption;
