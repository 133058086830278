import React from "react";
import { useTranslation } from "react-i18next";

import ActiveLink from "@components/ActiveLink";

const Navigation = () => {
  const { t } = useTranslation();

  return (
    <nav>
      <ul>
        <li>
          <ActiveLink href="/blog/">
            <span>{t("navigation.blog")}</span>
          </ActiveLink>
        </li>
        <li>
          <ActiveLink href="/projects/">
            <span>{t("navigation.projects")}</span>
          </ActiveLink>
        </li>
        <li>
          <ActiveLink href="/about/">
            <span>{t("navigation.about")}</span>
          </ActiveLink>
        </li>
        <li>
          <ActiveLink href="/social/">
            <span>{t("navigation.social")}</span>
          </ActiveLink>
        </li>
        <li>
          <ActiveLink href="/contact/">
            <span>{t("navigation.contact")}</span>
          </ActiveLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
