import React, { useContext, useState } from "react";
import { LocaleContext } from "gatsby-theme-i18n";

import ActiveLink from "@components/ActiveLink";
import LanguageOption from "@components/LanguageOption";

import { Location as LocationContext } from "@contexts/location";

import EnglishIcon from "@images/icons/lang-en.svg";
import CzechIcon from "@images/icons/lang-cs.svg";

const LanguageSelect = () => {
  const [isOpen, setIsOpen] = useState(false);
  const locale = useContext(LocaleContext);
  const location = useContext(LocationContext);
  const pathWithoutLanguage = location.pathname?.replace(/(en|cs)\/?/, "");
  const close = () => {
    setIsOpen(false);
  };

  let selectedLanguage;

  switch (locale) {
    case "cs":
      selectedLanguage = { name: "Čeština", icon: CzechIcon };
      break;

    case "en":
    default:
      selectedLanguage = { name: "English", icon: EnglishIcon };
  }

  return (
    <div className="language-select">
      <div className="languages" hidden={!isOpen}>
        <ActiveLink href={pathWithoutLanguage} language="en" onClick={close}>
          <LanguageOption icon={EnglishIcon} label="English" />
        </ActiveLink>
        <ActiveLink href={pathWithoutLanguage} language="cs" onClick={close}>
          <LanguageOption icon={CzechIcon} label="Čeština" />
        </ActiveLink>
      </div>
      <div className="selected-language" onClick={() => setIsOpen(!isOpen)}>
        <LanguageOption
          icon={selectedLanguage.icon}
          alt={selectedLanguage.name}
        />
      </div>
    </div>
  );
};

export default LanguageSelect;
