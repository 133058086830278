import React from "react";
import PropTypes from "prop-types";

const Quote = ({ children, className = "" }) => {
  return <div className={`quote ${className}`}>{children}</div>;
};

Quote.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Quote;
