import React from "react";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import PropTypes from "prop-types";

const ActiveLink = ({
  href,
  children,
  language = null,
  ...passThroughProps
}) => {
  return (
    <Link
      to={href}
      activeClassName="active-link"
      language={language}
      {...passThroughProps}
    >
      {children}
    </Link>
  );
};

ActiveLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  language: PropTypes.string,
};

export default ActiveLink;
