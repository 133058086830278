import React from "react";

import Quote from "@components/Quote";

const MainQuote = () => {
  return (
    <Quote className="main-quote">
      The steps you take can disrupt industries
    </Quote>
  );
};

export default MainQuote;
